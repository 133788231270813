@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
html {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif;
}
* {
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  outline: none;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  text-align: left;
}

button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.MuiFormControlLabel-labelPlacementTop {
    margin-left: 25px !important;
}

.mobileStoreSwitch > .MuiTypography-root {
  font-size: 15px;
}