@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.main {
  max-width: 100vw;
  max-height: 100vh;
  background-color: #f5f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landingContainer {
  height: 100vh;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.landingContainer h1 {
  color: #1d4c6f;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  transform: translateY(-50%);
  animation: render 1s forwards;
}
.landingContainer img {
  margin-bottom: 25px;
  transform: translateY(-50%);
  animation: render 1s forwards, logo 2s ease-in-out 1.75s infinite;
}
.btnContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
  height: 25%;
  opacity: 0;
  text-align: center;
  font-family: 'Inter', sans-serif;
  transform: translateY(-35%);
  animation: render 1.25s 0.5s forwards;
}
.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formContainer {
  height: 300px;
  flex-grow: 0.2;
  width: 350px;
  background-color: #fff;
  border-radius: 3px;
  padding: 32px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 40, 100, 0.12);
}
.inputsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.passwordInput{
  width: 30px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 40, 100, 0.5);
  margin-top: 0.75rem;
}
.forgotPasswordBtn{
  margin-top: 0.75rem;
  color: #1D4C6F;
}
.forgotPasswordBtn:hover{
  text-decoration: underline;
}
.verificationIcon{
  color: green;
  font-size: 50px;
}

@keyframes render {
  0% {
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
@keyframes logo {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(4deg);
  }
  66% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  .landingContainer{
    width: 100vw;
  }
  .btnContainer{
    width: 90%;
  }
  .landingContainer h1 {
    color: #1d4c6f;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 36px;
    text-align: center;
    transform: translateY(-50%);
    animation: render 1s forwards;
  }
}