@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.notauth_main__2X17E {
  max-width: 100vw;
  max-height: 100vh;
  background-color: #f5f7fb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notauth_landingContainer__Fps7X {
  height: 100vh;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.notauth_landingContainer__Fps7X h1 {
  color: #1d4c6f;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: notauth_render__3DAX2 1s forwards;
          animation: notauth_render__3DAX2 1s forwards;
}
.notauth_landingContainer__Fps7X img {
  margin-bottom: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: notauth_render__3DAX2 1s forwards, notauth_logo__Zkz7I 2s ease-in-out 1.75s infinite;
          animation: notauth_render__3DAX2 1s forwards, notauth_logo__Zkz7I 2s ease-in-out 1.75s infinite;
}
.notauth_btnContainer__2FcL8 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
  height: 25%;
  opacity: 0;
  text-align: center;
  font-family: 'Inter', sans-serif;
  -webkit-transform: translateY(-35%);
          transform: translateY(-35%);
  -webkit-animation: notauth_render__3DAX2 1.25s 0.5s forwards;
          animation: notauth_render__3DAX2 1.25s 0.5s forwards;
}
.notauth_container__2vR2Y {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.notauth_formContainer__Tqt2Q {
  height: 300px;
  flex-grow: 0.2;
  width: 350px;
  background-color: #fff;
  border-radius: 3px;
  padding: 32px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 40, 100, 0.12);
}
.notauth_inputsContainer__1URo8 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.notauth_passwordInput__1PgCh{
  width: 30px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 40, 100, 0.5);
  margin-top: 0.75rem;
}
.notauth_forgotPasswordBtn__1cys3{
  margin-top: 0.75rem;
  color: #1D4C6F;
}
.notauth_forgotPasswordBtn__1cys3:hover{
  text-decoration: underline;
}
.notauth_verificationIcon__2IOlj{
  color: green;
  font-size: 50px;
}

@-webkit-keyframes notauth_render__3DAX2 {
  0% {
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

@keyframes notauth_render__3DAX2 {
  0% {
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}
@-webkit-keyframes notauth_logo__Zkz7I {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  66% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes notauth_logo__Zkz7I {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  66% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  .notauth_landingContainer__Fps7X{
    width: 100vw;
  }
  .notauth_btnContainer__2FcL8{
    width: 90%;
  }
  .notauth_landingContainer__Fps7X h1 {
    color: #1d4c6f;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 36px;
    text-align: center;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-animation: notauth_render__3DAX2 1s forwards;
            animation: notauth_render__3DAX2 1s forwards;
  }
}
body,
html {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif;
}
* {
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  outline: none;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  text-align: left;
}

button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.MuiFormControlLabel-labelPlacementTop {
    margin-left: 25px !important;
}

.mobileStoreSwitch > .MuiTypography-root {
  font-size: 15px;
}
.providers_providerButtons__2en28 {
  transition: 0.3s;
  align-self: flex-end;
}
.providers_providerButtons__2en28:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.providers_calendar-container__lPcYa {
  background-color: red;
}

.providers_tooltiptext__3tAjO {
  opacity: 1;
  background-color: #151515;
  color: #fff;
  z-index: 1;
  width: '100%';
  padding: 10px;
  transition: 0.5s;
}

.providers_centered__1s5-Q {
  text-align: center;
}

.providers_vehicleSelect__3FAmv {
  width: 70%;
}
.calendar-container {
  width: 100%;
  height: 100%;
  z-index: 5;
  height: 60px;
}
.react-datetime-picker__wrapper{
  border: 1px solid rgba(0, 40, 100, 0.12);
}

.screen-container {
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 30%;
  justify-content: space-evenly;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

.content-container img {
  width: 20%;
  height: auto;
  align-self: center;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    opacity: 0.75;
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    opacity: 0.75;
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}

@media (max-width: 1024px) {
  .content-container{   
    width: 75vw;
  }
}
