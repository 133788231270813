.providerButtons {
  transition: 0.3s;
  align-self: flex-end;
}
.providerButtons:hover {
  transform: scale(1.05);
}

.calendar-container {
  background-color: red;
}

.tooltiptext {
  opacity: 1;
  background-color: #151515;
  color: #fff;
  z-index: 1;
  width: '100%';
  padding: 10px;
  transition: 0.5s;
}

.centered {
  text-align: center;
}

.vehicleSelect {
  width: 70%;
}