.screen-container {
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 30%;
  justify-content: space-evenly;
  opacity: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
}

.content-container img {
  width: 20%;
  height: auto;
  align-self: center;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    opacity: 0.75;
  }
  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

@media (max-width: 1024px) {
  .content-container{   
    width: 75vw;
  }
}